@import "https://fonts.googleapis.com/css?family=Montserrat:300,500,600|Roboto:100,100i,300,300i,700,700i&display=swap";
:root {
  --container-width: 1360px;
  --border-radius: 4px;
  --gutter: 20px;
  --gutter-2: calc(var(--gutter) * 2);
  --gutter-4: calc(var(--gutter) * 4);
  --section-gutter: 80px;
  --background-color: #fff;
  --primary-color: #012638;
  --primary-header-color: #333;
  --primary-hr-color: #ddd;
  --primary-font-color: #666;
  --secondary-color: #f7f8f8;
  --secondary-header-color: #333;
  --secondary-hr-color: #ddd;
  --secondary-font-color: #666;
  --tertiary-color: #2d7eb3;
  --tertiary-header-color: #fff;
  --tertiary-hr-color: #3289c1;
  --tertiary-font-color: #fff;
  --a-color: #3873b2;
  --a-hover-color: #66abf5;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  box-sizing: border-box;
  background-color: var(--primary-color);
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  min-width: 320px;
  text-rendering: optimizelegibility;
  color: var(--primary-font-color);
  letter-spacing: .005em;
  font: 100 20px / 1.5 Roboto, sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h5 {
  color: var(--primary-header-color);
  margin: 0 0 .7em;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  line-height: 1.2em;
}

h1 {
  margin-bottom: var(--gutter-2);
  font-size: 32px;
}

@media screen and (width >= 425px) {
  h1 {
    font-size: 42px;
  }
}

@media screen and (width >= 768px) {
  h1 {
    font-size: 48px;
  }
}

h2 {
  font-size: 20px;
}

@media screen and (width >= 425px) {
  h2 {
    font-size: 24px;
  }
}

@media screen and (width >= 768px) {
  h2 {
    font-size: 32px;
  }
}

@keyframes link-hover {
  from {
    transform: scaleX(0);
  }

  60% {
    transform: scaleX(.2);
  }

  to {
    transform: scaleX(1);
  }
}

a {
  color: var(--a-color);
  cursor: pointer;
  outline-style: none;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

a:hover, a:active, a:focus {
  color: var(--a-hover-color);
}

main a:not(.button):not(.no-link-hover):hover:after, main a:not(.button):not(.no-link-hover):active:after, main a:not(.button):not(.no-link-hover):focus:after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--a-hover-color);
  animation-name: link-hover;
  animation-duration: .2s;
  display: inline-block;
  position: absolute;
  bottom: -.3em;
  left: 0;
  right: 0;
}

p {
  margin: 0 0 1.5em;
}

p:last-child {
  margin-bottom: 0;
}

hr {
  max-width: 150px;
  height: 4px;
  background-color: var(--primary-hr-color);
  margin: var(--gutter-2) auto;
  text-align: center;
  border: none;
}

.container {
  width: 100%;
  padding: var(--gutter);
  margin: 0 auto;
}

@media screen and (width >= 520px) {
  .container {
    padding: var(--gutter-2);
  }
}

@media screen and (width >= 960px) {
  .container {
    max-width: var(--container-width);
    padding: var(--gutter-4);
  }
}

header, nav {
  background-color: var(--primary-color);
}

nav.container {
  align-items: center;
  gap: var(--gutter) 0;
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  color: var(--secondary-color);
  text-transform: uppercase;
  white-space: nowrap;
  flex-flow: column wrap;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  display: flex;
}

@media screen and (width >= 425px) {
  nav.container {
    flex-flow: row;
    justify-content: space-between;
  }
}

nav.container a {
  color: var(--secondary-color);
  letter-spacing: .1em;
  padding: 8px 10px;
  font-size: 14px;
  display: block;
}

nav.container a:hover, nav.container a:active, nav.container a:focus {
  color: var(--a-hover-color);
}

nav.container a.site-name {
  white-space: nowrap;
  text-transform: uppercase;
  padding: 0;
  font-size: 24px;
  display: block;
}

nav.container a.site-name:hover, nav.container a.site-name:active, nav.container a.site-name:focus {
  color: var(--a-hover-color);
}

nav.container ul {
  flex-grow: 1;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media screen and (width >= 425px) {
  nav.container ul {
    flex-grow: 0;
  }
}

main {
  background-color: var(--background-color);
}

main > section {
  border-top: 1px solid #e1e1e1;
}

main > section > article h1, main > section > section h1 {
  text-align: center;
}

section.secondary {
  background-color: var(--secondary-color);
  color: var(--secondary-font-color);
}

section.secondary h1, section.secondary h2, section.secondary h3, section.secondary h4, section.secondary h5 {
  color: var(--secondary-header-color);
}

section.tertiary {
  background-color: var(--tertiary-color);
  color: var(--tertiary-font-color);
}

section.tertiary h1, section.tertiary h2, section.tertiary h3, section.tertiary h4, section.tertiary h5 {
  color: var(--tertiary-header-color);
}

.section-description {
  text-align: center;
  margin-bottom: var(--gutter-4);
  letter-spacing: .1em;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 300;
}

.link-button {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  min-width: 330px;
  min-height: 60px;
  clear: both;
  background-color: #0a914e;
  border-radius: 3px;
  margin: 0 auto 1.4em;
  padding: 20px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

@media screen and (width <= 400px) {
  .link-button {
    min-width: 100%;
  }
}

.link-button:last-child {
  margin-bottom: 0;
}

.link-button:hover, .link-button:focus {
  color: #fff;
  background-color: #2cb17d;
}

.about-me-image {
  max-width: 75%;
  width: 100%;
  margin: var(--gutter-2) auto;
  background: url("me.cd30dd90.jpg") center / contain no-repeat;
  border-radius: 50%;
  overflow: hidden;
}

@media screen and (width >= 520px) {
  .about-me-image {
    max-width: 350px;
    margin: 0 auto var(--gutter-2);
  }
}

.about-me-image:before {
  content: "";
  float: left;
  padding-bottom: 100%;
}

.my-way, .my-education {
  text-align: center;
}

.my-way h3, .my-education h3 {
  margin: 0;
}

.my-way .period, .my-education .period {
  display: block;
}

.my-way .items, .my-education .items {
  gap: var(--gutter-4) 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-flow: row dense;
  display: grid;
}

@media screen and (width >= 425px) {
  .my-way .items, .my-education .items {
    gap: var(--gutter-4) var(--gutter-2);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width >= 768px) {
  .my-way .items, .my-education .items {
    gap: var(--gutter-4);
    grid-template-columns: repeat(3, 1fr);
  }
}

.my-way .note {
  margin-top: var(--gutter-4);
}

.open-source {
  background-color: #f7f8f8;
}

.open-source .item:not(:last-child) {
  margin-bottom: var(--gutter-4);
}

.cv {
  text-align: center;
}

.contact-info {
  gap: var(--gutter-2) 0;
  text-align: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-flow: row dense;
  display: grid;
}

@media screen and (width >= 768px) {
  .contact-info {
    gap: 0 var(--gutter-2);
    grid-template-columns: repeat(3, 1fr);
  }
}

.pgp {
  text-align: center;
}

.pgp i {
  color: var(--secondary-font-color);
  font-size: 80px;
  display: block;
}

footer {
  background-color: var(--primary-color);
  color: #fff;
  text-transform: uppercase;
}

footer .container {
  padding: var(--gutter-2) 0;
  display: block;
}

@media screen and (width >= 768px) {
  footer .container {
    padding: var(--gutter-2);
    align-items: center;
    display: flex;
  }
}

footer a {
  color: #fff;
  padding: 10px;
  display: inline-block;
}

footer a:hover, footer a:focus {
  color: var(--a-hover-color);
}

footer p {
  text-align: center;
  flex-grow: 1;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
}

footer p:last-child {
  margin-bottom: 0;
}

@media screen and (width >= 768px) {
  footer p {
    margin-bottom: 0;
  }

  footer p.social {
    text-align: center;
    order: 2;
  }

  footer p.copy {
    text-align: center;
    order: 1;
  }

  footer p.location {
    order: 3;
  }
}

.error-404 body {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.error-404 main {
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

/*# sourceMappingURL=index.4b08ec22.css.map */
